import { defineStore } from "pinia";
import { ref } from "vue";

export const useUploadStore = defineStore("upload", () => {
  // 存储所有上传任务
  const uploadTasks = ref<Map<string, UploadTask>>(new Map());

  // 添加上传任务
  const addTask = (taskId: string, task: UploadTask) => {
    uploadTasks.value.set(taskId, task);
  };

  // 移除上传任务
  const removeTask = (taskId: string) => {
    uploadTasks.value.delete(taskId);
  };

  return { uploadTasks, addTask, removeTask };
});

// 上传任务类型定义
interface UploadTask {
  progress: number; // 进度 0-100
  abortController: AbortController; // 用于取消上传
  file: File; // 上传文件对象
  bytes:number;//速率
}