import FetchRequest from "@/api/FetchRequest";
class aiFolder {
    static url = "/ai/voice/folder";
    // 文件夹新增
    static addFoldere( folderName: string, parentId: number): Promise<any> {
        const data = {
            
            folderName: folderName,
            parentId: parentId,
        }
        return FetchRequest.post(this.url ,  JSON.stringify(data), true);
    }
    // 编辑文件夹
    static editFoldere(folderId: number, folderName: string, parentId: number): Promise<any> {
        const data = {
            folderId: folderId,
            folderName: folderName,
            parentId: parentId,
        }
        return FetchRequest.put(this.url , JSON.stringify(data), true);
    }
    // 删除文件夹
    static delFoldere(integers: number, ): Promise<any> {
       
        return FetchRequest.del(this.url , JSON.stringify([integers]), true);
    }
    // 获取所有文件夹
    // static getFoldere(pageNum: number, pageSize: number): Promise<any> {
    //     return FetchRequest.get(this.url + '/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize, true);
    // }
    static getFoldere(): Promise<any> {
        return FetchRequest.get(this.url+'/folder/all' , true);
    }
  
}
export default aiFolder;