import { useUploadStore } from "@/store/uploadRate";
import { ref } from "vue";
import Auth from "@/api/Auth";
import axios from 'axios';
import FetchRequest from "@/api/FetchRequest";

export const uploadFile = async (file: File, taskId: string) => {
    const token = Auth.getToken();
    const uploadStore = useUploadStore();
    const abortController = new AbortController();
    // 创建 FormData
    const formData = new FormData();
    formData.append("file", file);

    // 添加任务到全局状态
    uploadStore.addTask(taskId, {
        progress: 0,
        abortController,
        file,
        bytes:0,
    });
    const formDatas = new FormData();
    formDatas.append('voiceFile', file);
    axios.put(FetchRequest.getHost() + '/vim/voice/change/upload/' + taskId, formDatas, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + token,
            'Content-Type': 'multipart/form-data'
        },

        onUploadProgress: progressEvent => {
            console.log(progressEvent)
            // // 进度事件处理函数
            const total = progressEvent.total || 1; // 总大小
            const uploadedSize = progressEvent.loaded || 0;
            const progress = Math.round((uploadedSize / total) * 100);
            const bytes=progressEvent.bytes || 0
            const task = uploadStore.uploadTasks.get(taskId);
            if (task) {
                task.progress = progress;
                task.bytes = bytes;
                uploadStore.uploadTasks.set(taskId, task);
            }
        }
    })
        .then(response => {
            // setTimeout(() => {
            //     uploadStore.removeTask(taskId);
            // }, 5000); // 5秒后自动清除
            console.log('文件上传成功:', response);
            if(response.status==200){
                uploadStore.removeTask(taskId)
            }
        })
        .catch(error => {
            uploadStore.removeTask(taskId);
            console.error('文件上传失败:', error);
        });


    //   try {
    //     const response = await fetch("/api/upload", {
    //       method: "POST",
    //       body: formData,
    //       signal: abortController.signal,
    //       // 监听上传进度
    //       onUploadProgress: (progressEvent) => {
    //         const progress = Math.round(
    //           (progressEvent.loaded / progressEvent.total) * 100
    //         );
    //         const task = uploadStore.uploadTasks.get(taskId);
    //         if (task) {
    //           task.progress = progress;
    //           uploadStore.uploadTasks.set(taskId, task);
    //         }
    //       },
    //     });

    //     // 上传完成后移除任务（可自定义保留时间）
    //     setTimeout(() => {
    //       uploadStore.removeTask(taskId);
    //     }, 5000); // 5秒后自动清除

    //     return response.json();
    //   } catch (error) {
    //     uploadStore.removeTask(taskId);
    //     throw error;
    //   }
};